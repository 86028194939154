import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store";
import {openModal, setColabProjects, setOwnedProjects} from "./projectManagementSlice";
import {ProjectCard} from "./ProjectCard";
import {useEffect, useState} from "react";
import axiosInstance from "../axiosInstance";
import {BudgetItemType, ProjectResponse} from "./interfaces";
import {useNavigate} from "react-router-dom";
import { PieChart } from '@mui/x-charts/PieChart';
import {LineChart} from "@mui/x-charts";
import {createTheme, ThemeProvider} from "@mui/material";
import {ProjectTable} from "./ProjectTable";


export const ProjectFeed = () => {
    const [loading, setLoading] = useState<boolean>(true);

    const dispatch = useAppDispatch();
    const pm = useSelector((state: RootState) => state.projectManagement);

    const projects = pm.ownedProjects
    const colabProjects = pm.colabProjects

    const navigate = useNavigate()

    const [totalLoans, setTotalLoans] = useState<number>(0)
    const [totalFunds, setTotalFunds] = useState<number>(0)


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get<ProjectResponse>(
                    '/api/v1/project', ).then(r => {
                    dispatch(setOwnedProjects(r.data.owned))
                    dispatch(setColabProjects(r.data.colab))
                    setLoading(false);
                    console.log("After useEffect")
                    console.log(r.data.owned);
                });
            } catch (error) {
                // @ts-ignore
                if (error.response.status === 401) {
                    navigate("/login")
                }
            }
            setLoading(false);
        }
        fetchData();
    }, []);

    useEffect(() => {
        let total_loans = 0
        let total_cash = 0

        for (let i = 0; i < projects.length; i++) {
            if (projects[i].Budget !== null) {
                for (let j = 0; j < projects[i].Budget!.length; j++) {
                    console.log(projects[i].Budget![j])
                    if (projects[i].Budget![j].BIType === BudgetItemType.Loan) {
                        let a = projects[i].Budget![j].Amount
                        if (!isNaN(a))
                            total_loans += a
                    } else {
                        let a = projects[i].Budget![j].Amount
                        if (!isNaN(a))
                            total_cash += a
                    }
                }
            }
        }

        setTotalFunds(total_cash)
        setTotalLoans(total_loans)
    }, [projects])

    return (
        <div className="flex flex-col items-center h-screen overflow-y-hidden w-fit p-3">
            <div className="flex w-full gap-3">
                <div className="stats shadow">
                    <div className="stat">
                        <div className="stat-title">Total Projects</div>
                        <div className="stat-value">{projects.length + colabProjects.length}</div>
                        <div className="stat-desc">{`${colabProjects.length} Collaborations`}</div>
                    </div>
                </div>
                <div className="flex w-fit bg-base-100 shadow rounded">
                    <div className="stat w-fit">
                        <div className="stat-title">Total Budget</div>
                        <div className="stat-value">{`$ ${totalFunds + totalLoans}`}</div>
                        <div className="stat-desc">21% more than last month</div>
                    </div>
                    <ThemeProvider theme={createTheme({ palette: { mode: "dark" } })}>
                        <PieChart
                            series={[
                                {
                                    data: [
                                        {id: 0, value: totalLoans, label: 'Loan'},
                                        {id: 1, value: totalFunds, label: 'Cash'},
                                    ],
                                    innerRadius: 10,
                                    outerRadius: 20,
                                    paddingAngle: 5,
                                    cornerRadius: 5,
                                    startAngle: -45,
                                    endAngle: 225,
                                    cx: 40,
                                    cy: 50,
                                }
                            ]}
                            width={200}
                            height={110}
                        />
                    </ThemeProvider>
                </div>
                <div className="flex w-fit bg-base-100 shadow rounded overflow-y-hidden">
                    <div className="stat w-fit">
                        <div className="stat-title">Projected Debt</div>
                        <div className="stat-value">{`$ ${totalLoans}`}</div>
                        <div className="stat-desc">Over next 10 years</div>
                    </div>
                    <ThemeProvider theme={createTheme({palette: {mode: "dark"}})}>
                        <LineChart
                            xAxis={[{data: [1, 2, 3, 5, 8, 10]}]}
                            series={[
                                {
                                    data: [2, 5.5, 2, 8.5, 1.5, 5],
                                },
                            ]}
                            width={300}
                            height={150}
                        />
                    </ThemeProvider>
                </div>
            </div>
            <div className="flex justify-between w-full p-2 shadow m-2 rounded p-2 bg-base-100">
                <div className="flex gap-1.5">
                    <div className="dropdown dropdown-begin">
                        <div className="tooltip" data-tip="Filter">
                            <div tabIndex={0} role="button" className="btn btn-ghost btn-sm rounded">
                                <div className="indicator">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5} stroke="currentColor" className="size-5">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div
                            tabIndex={0}
                            className="card card-compact dropdown-content bg-base-100 z-[1] mt-3 w-52 shadow">
                            <div className="card-body">
                                <span className="text-lg font-bold">8 Items</span>
                                <span className="text-info">Subtotal: $999</span>
                                <div className="card-actions">
                                    <button className="btn btn-primary btn-block">View cart</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dropdown dropdown-begin">
                        <div className="tooltip" data-tip="Sort">
                            <div tabIndex={0} role="button" className="btn btn-ghost btn-sm rounded">
                                <div className="indicator">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         strokeWidth={1.5} stroke="currentColor" className="size-5">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M15.75 17.25 12 21m0 0-3.75-3.75M12 21V3"/>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div
                            tabIndex={0}
                            className="card card-compact dropdown-content bg-base-100 z-[1] mt-3 w-52 shadow">
                            <div className="card-body">
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">Updated</span>
                                        <input type="radio" name="radio-10" className="radio "
                                               defaultChecked/>
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">Created</span>
                                        <input type="radio" name="radio-10" className="radio "
                                               defaultChecked/>
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">Name</span>
                                        <input type="radio" name="radio-10" className="radio "
                                               defaultChecked/>
                                    </label>
                                </div>
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">Status</span>
                                        <input type="radio" name="radio-10" className="radio "
                                               defaultChecked/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label className="input input-bordered input-sm flex items-center gap-2">
                        <input type="text" className="grow" placeholder="Search"/>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            fill="currentColor"
                            className="h-4 w-4 opacity-70">
                            <path
                                fillRule="evenodd"
                                d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
                                clipRule="evenodd"/>
                        </svg>
                    </label>
                    <button className="btn btn-sm btn-outline rounded">
                        Search
                    </button>
                </div>
                <div className="flex gap-2">
                    <button
                        className="btn btn-sm btn-primary rounded"
                        onClick={() => dispatch(openModal())}>
                        New Project
                    </button>
                    <div className="tooltip" data-tip="Delete Selected">
                        <button className="btn btn-sm btn-outline rounded btn-error">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="size-4">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <ProjectTable />
        </div>
    )
}