import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import axiosInstance from '../axiosInstance';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {
    Activity,
    ActivityCreation, CreateActivityResponse,
    CreateProjectResponse,
    CreationStatus, DeleteProjectResponse,
    Project,
    ProjectCreation
} from "./interfaces";
import {AsyncThunkConfig} from "@reduxjs/toolkit/dist/createAsyncThunk";

export const createProject = createAsyncThunk(
    'projectManagement/createProject',
    async (projectData: ProjectCreation, thunkAPI) => {

        try {
            const response = await axiosInstance.post<CreateProjectResponse>(
                '/api/v1/project', projectData);

            console.log("After create project response");
            console.log(response.data);
            return response.data.data;

        } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    if (error.response.status === 401) {
                        const navigate = useNavigate();
                        navigate('/login');
                    } else {
                        return thunkAPI.rejectWithValue(error.response.data);
                    }
                }
            }
        }
    }
);

export const deleteProject = createAsyncThunk(
    'projectManagement/deleteProject',
    async (projectId: number, thunkAPI) => {
        try {
            const response = await axiosInstance.delete<DeleteProjectResponse>(
                `/api/v1/project/${projectId}`);

            return response.data.data;

        } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    if (error.response.status === 401) {
                        const navigate = useNavigate();
                        navigate('/login');
                    } else {
                        return thunkAPI.rejectWithValue(error.response.data);
                    }
                }
            }
        }
    }
)


export const createActivity = createAsyncThunk(
    'projectManagement/createActivity',
    async (data: ActivityCreation, thunkAPI) => {

        try {
            const response = await axiosInstance.post<CreateActivityResponse>(
                '/api/v1/activity', data);
            return response.data.data;

        } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    if (error.response.status === 401) {
                        const navigate = useNavigate();
                        navigate('/login');
                    } else {
                        return thunkAPI.rejectWithValue(error.response.data);
                    }
                }
            }
        }
    }
);

interface ProjectManagementState {
    createModalOpen: boolean;
    error: string;
    projectCreationStatus: string;
    activityCreationStatus: string;
    ownedProjects: Project[],
    colabProjects: Project[],
    creationStatus: CreationStatus,
    currentProject: string,
    activity: Activity[],
    selectedProjects: number[]
}

const initialState: ProjectManagementState = {
    createModalOpen: false,
    error: "",
    projectCreationStatus: "",
    activityCreationStatus: "",
    ownedProjects: [],
    colabProjects: [],
    creationStatus: CreationStatus.Success,
    currentProject: "",
    activity: [],
    selectedProjects:[]
}


const projectManagementSlice = createSlice({
    name: 'projectManagement',
    initialState: initialState,
    reducers: {
        openModal: (state) => {
            return {...state, createModalOpen: true};
        },
        closeModal: (state) => {
            return {...state, createModalOpen: false};
        },
        setOwnedProjects: (state, action: PayloadAction<Project[]>) => {
            state.ownedProjects = action.payload
        },
        setColabProjects: (state, action: PayloadAction<Project[]>) => {
            state.colabProjects = action.payload
        },
        addOwnedProject: (state, action) => {
            state.ownedProjects.push(action.payload)

        },
        addColabProject: (state, action) => {
            state.colabProjects.push(action.payload)
        },
        setCurrentProject: (state, action) => {
            state.currentProject = action.payload
        },
        setActivity: (state, action) => {
            state.activity = action.payload
        },
        addActivity: (state, action) => {
            state.activity.push(action.payload)
        },
        addSelectedProject: (state, action) => {
            state.selectedProjects.push(action.payload)
        },
        removeSelectedProject: (state, action) => {
            state.selectedProjects = state.selectedProjects.filter((item) => item !== action.payload)
        }
    },
    extraReducers: (builder) => {
        // The `builder` callback form is used here because it provides correctly typed reducers from the action creators
        builder.addCase(createProject.fulfilled, (state, { payload }) => {
            console.log("fufilled:")
            console.log(payload);
            state.creationStatus = CreationStatus.Success;
            if (payload)
                state.ownedProjects.push(payload);
        })
        builder.addCase(createProject.rejected, (state, action) => {
            if (action.payload) {
                //state.error = action.payload
                state.projectCreationStatus = "rejected"
            } else if (action.error.message) {
                state.error = action.error.message
            }
        })
        builder.addCase(createProject.pending, (state, action) => {
            state.projectCreationStatus = "pending"
        })

        builder.addCase(createActivity.fulfilled, (state, { payload }) => {
            console.log(payload);
            state.activityCreationStatus = "success";
            if (payload)
                state.activity.push(payload);
        })
        builder.addCase(createActivity.rejected, (state, action) => {
            if (action.payload) {
                //state.error = action.payload
                state.activityCreationStatus = "rejected"
            } else if (action.error.message) {
                state.error = action.error.message
            }
        })
        builder.addCase(createActivity.pending, (state, action) => {
            state.activityCreationStatus = "pending"
        })

        builder.addCase(deleteProject.fulfilled, (state, { payload }) => {
            console.log(`Removing id: ${payload}`);
            state.ownedProjects = state.ownedProjects.filter((item) => item.ID !== Number(payload))
            state.selectedProjects = state.selectedProjects.filter((item) => item !== Number(payload))
        })

    },
});

export const { openModal, closeModal, setOwnedProjects, setColabProjects, addOwnedProject, addColabProject,
                setCurrentProject, setActivity, addActivity, addSelectedProject, removeSelectedProject} = projectManagementSlice.actions;
export default projectManagementSlice.reducer;
