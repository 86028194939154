import React from 'react';
import { Link } from 'react-router-dom';

const UserPortal = () => {
  const user = localStorage.getItem('user');

  return (
    <div className="min-h-screen bg-base-200 text-base-content">
      <header className="bg-base-100 shadow-sm py-4 px-6">
        <h1 className="text-2xl font-bold">User Portal</h1>
      </header>

      <main className="container mx-auto py-8 px-4 md:px-8">
        <h2 className="text-xl font-semibold mb-6">{`Welcome, ${user}!`}</h2>
        <p className="mb-6">
          Here, you can manage your projects and access features that are personalized for you.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-base-100 shadow-lg rounded-lg p-6">
            <h3 className="text-lg font-semibold mb-4">Design</h3>
            <p className="mb-4">
              Bringing your vision to life with expert design tailored to your needs.
            </p>
            <Link to="/design" className="btn btn-primary">
              Go to Design
            </Link>
          </div>

          <div className="bg-base-100 shadow-lg rounded-lg p-6">
            <h3 className="text-lg font-semibold mb-4">Estimating</h3>
            <p className="mb-4">
              Utilizing web tools to ensure accurate and efficient cost estimating to meet any project budget.
            </p>
            <Link to="/estimating" className="btn btn-primary">
              Go to Estimating
            </Link>
          </div>

          <div className="bg-base-100 shadow-lg rounded-lg p-6">
            <h3 className="text-lg font-semibold mb-4">Project Management</h3>
            <p className="mb-4">
              Managing every aspect of your project to ensure success and satisfaction through sophisticated software.
            </p>
            <Link to="/project-management" className="btn btn-primary">
              Go to Project Management
            </Link>
          </div>
        </div>
      </main>

      <footer className="bg-base-100 shadow-sm py-4 text-center">
        <p className="text-sm">
          &copy; {new Date().getFullYear()} Urban Tectonic. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

export default UserPortal;
