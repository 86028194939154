import {BudgetItem, Project} from "./interfaces";
import React, {useState} from "react";
import {useAppDispatch} from "../store";
import {
    addSelectedProject,
    createProject,
    deleteProject,
    removeSelectedProject,
    setCurrentProject
} from "./projectManagementSlice";
import {ProjectRowProgressBar} from "./ProjectRowProgressBar";

interface IProjectCard {
    project: Project;
}


export const ProjectCard: React.FC<IProjectCard> = ({project}) => {

    const [selected, setSelected] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    return (
    <tr>
        <th>
            <label>
                <input type="checkbox" className="checkbox checkbox-sm" checked={selected} onChange={(e) => {
                    if (selected) {
                        dispatch(removeSelectedProject(project.ID))
                    } else if (!selected) {
                        dispatch(addSelectedProject(project.ID))
                    }
                    setSelected(!selected)
                }} />
            </label>
        </th>
        <td>
            <div className="flex items-center gap-3">
                <div>
                    <div className="font-bold">{project.Name}</div>
                    <div className="text-sm opacity-50">United States</div>
                </div>
            </div>
        </td>
        <td>
            {(new Date(project.UpdatedAt)).toLocaleString()}
        </td>
        <td>
            <ProjectRowProgressBar state={0} />
        </td>
        <th>
            <button className="btn btn-outline btn-primary btn-xs mr-2"
                    onClick={() => dispatch(setCurrentProject(project.Name))}>
                Open
            </button>
            <button className="btn btn-outline btn-xs mr-2"
                    onClick={() => dispatch(
                        createProject({Name: project.Name, Budget: project.Budget === null ? [] as BudgetItem[] : project.Budget}))
            }>
                Copy
            </button>
            <button className="btn btn-outline btn-xs btn-error" onClick={() => dispatch(deleteProject(project.ID))}>
                Delete
            </button>
        </th>
    </tr>
    )
}