import {openModal, setColabProjects, setOwnedProjects} from "./projectManagementSlice";
import {ProjectCard} from "./ProjectCard";
import {RootState, useAppDispatch} from "../store";
import {useEffect, useState} from "react";
import axiosInstance from "../axiosInstance";
import {ProjectResponse} from "./interfaces";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Calender} from "./Calender";
import {ActivityFeed} from "./ActivityFeed";
import {ProjectFeed} from "./ProjectFeed";


export const ProjectManagementFeeds = () => {

    return (
        <div className="flex w-full flex-grow">
            <ProjectFeed />
        </div>
    )
}