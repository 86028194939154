import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store";
import {closeModal, createProject} from "./projectManagementSlice";
import {BudgetItem, BudgetItemType} from "./interfaces";
import classNames from 'classnames';


export const CreateModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const pm = useSelector((state: RootState) => state.projectManagement);
    const display = pm.createModalOpen ? 'd-block' : 'hidden';
    const [currentPage, setCurrentPage] = useState<number>(0);

    // Page 1
    const [newProjectName, setNewProjectName] = useState<string>("");

    // Page 2
    const [budgetItems, setBudgetItems] = useState<BudgetItem[]>([]);
    const [currentLabel, setCurrentLabel] = useState<string>("");
    const [currentType, setCurrentType] = useState<BudgetItemType>(BudgetItemType.Cash);
    const [currentAmount, setCurrentAmount] = useState<string>("");
    // Page 3

    // Page 4

    const pages = [
        <>
            <label className="form-control w-full max-w-xs">
                <div className="label">
                    <span className="label-text">New Project Name</span>
                </div>
                <input type="text" placeholder="Project Name" className="input input-bordered w-full max-w-xs"
                       onChange={(e) => setNewProjectName(e.target.value)}/>
            </label>

            <div>
                <h1 className="font-bold">Collaborators</h1>
                <div className="flex">

                </div>
                <button className="btn btn-xs btn-primary btn-outline rounded">
                    Add Collaborator
                </button>
            </div>

            <div className="flex justify-end gap-3 border-t border-base-200 pt-5">
                <button type="button" onClick={() => dispatch(closeModal())}
                        className="btn btn-outline rounded btn-sm btn-error">
                    Close
                </button>
                <button type="button" onClick={() => {
                    setCurrentPage(currentPage + 1);
                }} className="btn btn-primary rounded btn-sm">
                    Next
                </button>
            </div>
        </>,

        <>
            <div className="overflow-x-auto w-full overflow-y-auto h-3/5 rounded bg-base-100">
                <table className="table table-pin-rows">
                    {/* head */}
                    <thead>
                    <tr>
                        <th>
                            <label>
                            </label>
                        </th>
                        <th>Label</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>
                            <label>
                                <div className="tooltip" data-tip="Add Item">
                                    <button className={classNames("btn btn-sm",
                                        {"btn-disabled": currentAmount === "" || currentLabel === ""})}
                                    onClick={(e) => {
                                        setBudgetItems(i => [...i, {label: currentLabel, BIType: currentType, Amount: Number(currentAmount)}]);
                                        setCurrentAmount("")
                                        setCurrentLabel("")
                                        setCurrentType(BudgetItemType.Cash)
                                    }} disabled={currentAmount === "" || currentLabel === ""}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="size-4">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M12 4.5v15m7.5-7.5h-15"/>
                                        </svg>
                                    </button>
                                </div>
                            </label>
                        </th>
                        <th>
                            <input
                                type="text"
                                placeholder="Budget item label"
                                className="input input-bordered w-full " value={currentLabel}
                                onChange={(e) => setCurrentLabel(e.target.value)}/>
                        </th>
                        <th>
                            <select className="select select-bordered  " onChange={(i) => {
                                if (i.target.value.toLowerCase() === "cash") {
                                    setCurrentType(BudgetItemType.Cash)
                                }
                                if (i.target.value.toLowerCase() === "loan") {
                                    setCurrentType(BudgetItemType.Loan)
                                }
                            }}>
                                <option>Cash</option>
                                <option>Loan</option>
                            </select>
                        </th>
                        <th>
                            <label className="input input-bordered flex items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                     strokeWidth={1.5} stroke="currentColor" className="size-6">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                          d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <input type="text" className="select-sm" value={currentAmount}
                                       onChange={(event) => setCurrentAmount(event.target.value.replace(/\D/, ''))}/>
                            </label>
                        </th>
                        <th></th>
                    </tr>
                    {
                        budgetItems.map((p, index) => {
                            return <tr id={`budget-item-${index}`}>
                                <th>
                                    <label>
                                    <button className="btn btn-sm" onClick={(e) => {
                                        setBudgetItems(budgetItems.filter((_, i) => i !== index))
                                    }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                 strokeWidth={1.5} stroke="currentColor" className="size-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14"/>
                                            </svg>
                                        </button>
                                    </label>
                                </th>
                                <th>{p.label}</th>
                                <th>{p.BIType === BudgetItemType.Cash ? "Cash": "Loan"}</th>
                                <th>{`$ ${p.Amount}`}</th>
                                <th></th>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
            <div className="flex mt-3">
                <h6>Total Budget: $</h6>
                <h6>{budgetItems.reduce((total, i) => total + i.Amount, 0)}</h6>
            </div>
            <div className="flex justify-end gap-3 border-t border-base-200 pt-5">
                <button type="button" onClick={() => dispatch(closeModal())}
                        className="btn btn-outline btn-error rounded btn-sm">
                    Close
                </button>
                <button type="button" onClick={() => {
                    setCurrentPage(currentPage - 1);
                }} className="btn btn-secondary rounded btn-sm btn-outline">
                    Back
                </button>
                <button type="button" onClick={() => {
                    setCurrentPage(currentPage + 1);
                }} className="btn btn-primary rounded btn-sm">
                Next
                </button>
            </div>
        </>,
        <>
            <input type="file" className="file-input file-input-bordered file-input-sm w-full max-w-xs"/>
            <h6 className="font-bold mt-3">Files</h6>

            <div className="flex justify-end gap-3 border-t border-base-200 pt-5">
                <button type="button" onClick={() => dispatch(closeModal())}
                        className="btn btn-outline rounded btn-error btn-sm ">
                    Close
                </button>
                <button type="button" onClick={() => {
                    setCurrentPage(currentPage - 1);
                }} className="btn btn-secondary rounded btn-sm btn-outline">
                    Back
                </button>
                <button type="button" onClick={() => {
                    setCurrentPage(currentPage + 1);
                }} className="btn btn-primary rounded btn-sm">
                    Next
                </button>
            </div>
        </>,
        <>


            <div className="flex justify-end gap-3 border-t border-base-200 pt-5">
                <button type="button" onClick={() => dispatch(closeModal())}
                        className="btn btn-outline rounded btn-error btn-sm">
                    Close
                </button>
                <button type="button" onClick={() => {
                    setCurrentPage(currentPage - 1);
                }} className="btn btn-secondary btn-outline rounded btn-sm">
                    Back
                </button>
                <button type="button" onClick={() => {
                    dispatch(createProject({Name: newProjectName, Budget: budgetItems}))
                    setBudgetItems([])
                    setCurrentPage(0)
                    setNewProjectName("")
                    dispatch(closeModal())
                }} className="btn btn-primary rounded btn-sm">
                    Create Project
                </button>
            </div>
        </>

    ]

    return (
        <div className={`${display} fixed top-10 z-10 w-3/5 max-h-4/5 bg-base-100  rounded-xl shadow-2xl`}>
            <section className="flex flex-col p-6 gap-6">
                <div className="flex w-full justify-center border-b border-base-200 p-3">
                    <ul className="steps">
                        <li className={classNames("step", {"step-primary": currentPage >= 0})}>Overview</li>
                        <li className={classNames("step", {"step-primary": currentPage >= 1})}>Budget</li>
                        <li className={classNames("step", {"step-primary": currentPage >= 2})}>File Upload</li>
                        <li className={classNames("step", {"step-primary": currentPage >= 3})}>Finish</li>
                    </ul>
                </div>
                {pages[currentPage]}
            </section>
        </div>
    );
};