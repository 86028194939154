import React from "react";


interface IProgressBar {
    state: number;
}

export const ProjectRowProgressBar: React.FC<IProgressBar> = ({state}) => {

    if (state === 0)
        return (
            <ul className="timeline">
                <li>
                    <div className="timeline-start"></div>
                    <hr className="bg-primary"/>
                </li>
                <li>
                    <hr className=""/>
                    <div className="timeline-start">Created</div>
                    <hr/>
                </li>
                <li>
                    <hr className=""/>
                    <div className="timeline-start"></div>
                </li>
            </ul>

        )

    if (state === 1)
        return (
            <ul className="timeline">
                <li>
                    <div className="timeline-start"></div>
                    <div className="timeline-middle">

                    </div>
                    <hr className="bg-primary"/>
                </li>
                <li>
                    <hr className="bg-primary"/>
                    <div className="timeline-start">In Progress</div>
                    <hr/>
                </li>
                <li>
                    <hr className=""/>
                    <div className="timeline-start"></div>
                    <div className="timeline-middle">

                    </div>
                </li>
            </ul>
        )

    return (
        <ul className="timeline">
            <li>
                <div className="timeline-start"></div>

                <hr className="bg-primary"/>
            </li>
            <li>
                <hr className="bg-primary"/>
                <div className="timeline-start">Complete</div>
                <hr className="bg-primary"/>
            </li>
            <li>
                <hr className="bg-primary"/>
                <div className="timeline-start"></div>
            </li>
        </ul>
    )
}