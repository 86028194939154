import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const { pathname } = location;

  let textColor = 'text-white';
  let borderColor = 'border-white';
  let hoverBgColor = 'hover:bg-white';
  let hoverTextColor = 'hover:text-black';
  let headerBg = 'bg-transparent';
  let padding = 'p-6';

  if (pathname !== '/') {
    textColor = 'text-black';
    borderColor = 'border-black';
    hoverBgColor = 'hover:bg-black';
    hoverTextColor = 'hover:text-white';
    headerBg = 'bg-gray shadow-md';
    padding = 'p-3';
  }

  return (
    <header className={`fixed top-0 left-0 w-full z-20 ${padding} ${headerBg}`}>
      <div className="container mx-auto flex justify-end items-center space-x-6">
        {pathname === '/' ? (
          <>
            <a
              href="#hero"
              className={`px-4 py-2 border ${borderColor} ${textColor} rounded-md ${hoverBgColor} ${hoverTextColor} transition duration-300`}
            >
              Home
            </a>
            <a
              href="#about"
              className={`px-4 py-2 border ${borderColor} ${textColor} rounded-md ${hoverBgColor} ${hoverTextColor} transition duration-300`}
            >
              About Us
            </a>
            <a
              href="#services"
              className={`px-4 py-2 border ${borderColor} ${textColor} rounded-md ${hoverBgColor} ${hoverTextColor} transition duration-300`}
            >
              Services
            </a>
            <a
              href="#contact"
              className={`px-4 py-2 border ${borderColor} ${textColor} rounded-md ${hoverBgColor} ${hoverTextColor} transition duration-300`}
            >
              Contact
            </a>
          </>
        ) : (
          <Link
            to="/"
            className={`px-4 py-2 border ${borderColor} ${textColor} rounded-md ${hoverBgColor} ${hoverTextColor} transition duration-300`}
          >
            Home
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
