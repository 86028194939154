import React from "react";
import {RootState, useAppSelector} from "../store";
import {ProjectCard} from "./ProjectCard";


export const ProjectTable: React.FC = () => {
    const projects = useAppSelector((state: RootState) => state.projectManagement.ownedProjects);
    console.log("Rendering projects table");
    console.log(projects);

    return (
        <div role="tablist" className="tabs tabs-bordered w-full bg-base-100 shadow-lg min-h-fit max-h-[65%] overflow-y-hidden p-1">
            <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Owned Projects" defaultChecked/>
            <div role="tabpanel"
                 className="tab-content overflow-x-auto w-full overflow-y-auto h-full rounded bg-base-100">
                <table className="table table-xs table-pin-rows z-0">
                    <thead>
                    <tr>
                        <th>
                            <label>
                                <input type="checkbox" className="checkbox checkbox-sm" defaultChecked={true}/>
                            </label>
                        </th>
                        <th>Name</th>
                        <th>Last Updated</th>
                        <th>Status</th>
                        <th>Actions</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        projects.map((p) => {
                            return <ProjectCard project={p}/>
                        })
                    }
                    </tbody>
                    {/* foot */}
                    <tfoot>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Last Updated</th>
                        <th>Status</th>
                        <th>Actions</th>
                        <th></th>
                    </tr>
                    </tfoot>
                </table>
            </div>

            <input
                type="radio"
                name="my_tabs_1"
                role="tab"
                className="tab"
                aria-label="Collab Projects"
                />
            <div role="tabpanel" className="tab-content p-10">Tab content 2</div>

            <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Portfolios"/>
            <div role="tabpanel" className="tab-content p-10">Tab content 3</div>
        </div>
    )

}