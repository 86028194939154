import {useEffect, useState} from "react";
import axiosInstance from "../axiosInstance";
import {ProjectCard} from "./ProjectCard";
import {Project, ProjectResponse} from "./interfaces";
import {CreateModal} from "./CreateModal";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../store";
import {openModal, setColabProjects, setOwnedProjects} from "./projectManagementSlice";
import {ProjectManagementFeeds} from "./ProjectManagementFeeds";
import {ProjectView} from "./ProjectView";


export const ProjectManagementMain = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    const pm = useSelector((state: RootState) => state.projectManagement);

    return (
        <div className="flex flex-col h-screen overflow-y-hidden items-center bg-base-200">
            <div className="navbar bg-base-100 shadow">
                <div className="navbar-start">
                    <div className="flex flex-col">
                        <a href="/home" className="btn btn-ghost text-xl p-0 btn-sm">UrbanTectonic</a>
                        <h5 className="text-xs">
                            Project Management
                            <span className="badge badge-xs">v0.0.1</span>
                        </h5>
                    </div>
                </div>
                <div className="navbar-end">
                    <button className="btn btn-ghost btn-circle">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/>
                        </svg>
                    </button>
                    <button className="btn btn-ghost btn-circle">
                        <div className="indicator">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
                            </svg>
                            <span className="badge badge-xs badge-primary indicator-item"></span>
                        </div>
                    </button>
                    <div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
                        <div className="w-10 rounded-full">
                            <img
                                alt="Tailwind CSS Navbar component"
                                src="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.webp"/>
                        </div>
                    </div>

                </div>
            </div>
            <div className="flex justify-between w-full">
                <div className="breadcrumbs text-xs">
                    <ul>
                        <li>
                            <a href="/user-portal">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    className="h-4 w-4 stroke-current">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>
                                </svg>
                                User Portal
                            </a>
                        </li>
                        <li>
                            <a href="/project-management">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    className="h-4 w-4 stroke-current">
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>
                                </svg>
                                Project Management
                            </a>
                        </li>
                        {
                            pm.currentProject === "" ? <></> :
                                <li>
                                    <a>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            className="h-4 w-4 stroke-current">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>
                                        </svg>
                                        {pm.currentProject}
                                    </a>
                                </li>
                        }
                    </ul>
                </div>
            </div>
            {
                pm.currentProject === "" ? <ProjectManagementFeeds/> : <ProjectView/>
            }
            <CreateModal/>
        </div>
    )
}