

export interface User {
    ID: number,
    CreatedAt: string,
    UpdatedAt: string,
    DeletedAt: string,
    username: string;
    password: string;
}

export interface Project {
    ID: number;
    Name: string;
    CreatedAt: string;
    UpdatedAt: string;
    DeletedAt: string;
    UserID: number;
    Budget: BudgetItem[] | null
}

export interface ProjectCreation {
    Name: string;
    Budget: BudgetItem[]
}

export interface ProjectResponse {
    owned: Project[];
    colab: Project[];
    message: string;
}

export interface CreateProjectResponse {
    message: string;
    data: Project;
}

export interface DeleteProjectResponse {
    message: string;
    data: number
}

export enum CreationStatus {
    Success = 1,
    Failed ,
    Pending = 2,
}

export interface ActivityCreation {
    Description: string;
}

export interface Activity {
    UserID: number;
    Description: string;
}

export interface CreateActivityResponse {
    message: string;
    data: Activity;
}

export enum BudgetItemType {
    Cash,
    Loan
}

export interface BudgetItem {
    label: string;
    BIType: BudgetItemType;
    Amount: number;
}