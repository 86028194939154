import {useSelector} from "react-redux";
import {RootState} from "../store";
import {useEffect, useState} from "react";
import {createTheme, ThemeProvider} from "@mui/material";
import {LineChart} from "@mui/x-charts";
import { Gantt, WillowDark } from "wx-react-gantt";
//import "wx-react-gantt/dist/gantt.css";

export const ProjectView = () => {
    const pm = useSelector((state: RootState) => state.projectManagement);
    const [projectView, setProjectView] = useState<string>("Overview")


    return (
        <div className="flex flex-col h-full w-full">
            <div className="flex gap-2 items-center">
                <h6>{pm.currentProject}</h6>
                <select name="view" id="project-view" className="p-2 rounded"
                        onChange={(e) => setProjectView(e.target.value)}>
                    <option value="overview">Overview</option>
                    <option value="design">Design</option>
                    <option value="estimate">Estimate</option>
                </select>
            </div>
            <div className="flex gap-2 items-center mt-2 flex-grow p-2">
                <div className="flex flex-col w-3/5 h-full gap-2">
                    <div className="h-1/2 w-full bg-base-100 shadow-lg flex p-2">
                        <div>
                            <p>Loan</p>
                        </div>
                        <ThemeProvider theme={createTheme({palette: {mode: "dark"}})}>
                            <LineChart
                                xAxis={[{data: [1, 2, 3, 5, 8, 10]}]}
                                series={[
                                    {
                                        data: [2, 5.5, 2, 8.5, 1.5, 5],
                                    },
                                ]}
                            />
                        </ThemeProvider>
                    </div>
                    <div className="h-1/2 w-full bg-base-100 shadow-lg flex p-2 gap-2">
                        <div id="controls-container" >
                            <form id="add-task" className="flex flex-col gap-2">
                                <h2>Add task</h2>
                                <label htmlFor="task-name">Task name</label>
                                <input id="task-name" name="task-name" required/>
                                <label htmlFor="start-date">Start date</label>
                                <input
                                    type="date"
                                    id="start-date"
                                    name="start-date"
                                    value="2022-11-08"
                                    min="2022-01-01"
                                    max="2050-12-31"
                                />
                                <label htmlFor="end-date">End date</label>
                                <input
                                    type="date"
                                    id="end-date"
                                    name="end-date"
                                    value="2022-11-10"
                                    min="2022-01-01"
                                    max="2050-12-31"
                                />
                                <label htmlFor="progress">Progress (%)</label>
                                <input
                                    type="number"
                                    id="progress"
                                    name="progress"
                                    value="0"
                                    min="0"
                                    max="100"
                                />
                                <div className="form-control">
                                    <label className="label cursor-pointer">
                                        <span className="label-text">Important?</span>
                                        <input type="checkbox" defaultChecked className="checkbox"/>
                                    </label>
                                </div>
                                <button className="btn btn-sm">Add</button>
                            </form>
                        </div>
                        <WillowDark>
                            <Gantt cellHeight={16} />
                        </WillowDark>
                    </div>
                </div>
            </div>
        </div>
    )
}